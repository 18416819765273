<script setup>
import {inject, ref, watch, defineModel} from "vue";
import LoadingScreen from "@/components/partials/LoadingScreen.vue";
import DropZone from "@/components/DropZone.vue";
import FilePreview from "@/components/partials/FilePreview.vue";
import InlineAlert from "@/components/partials/InlineAlert.vue";
import ErrorScreen from "@/components/partials/ErrorScreen.vue";

defineProps(['freeQuote'])
const emit = defineEmits(['step1to2'])
const isLoading = defineModel('isLoading')
const { uuid } = inject('uuid')

import useFileList from '@/compositions/file-list'
const { files, refFiles, fileWarning, addFiles, removeFile, addRefFiles, removeRefFile} = useFileList()

import useInlineAlert from '@/compositions/inline-alert'
import createUploader from "@/compositions/file-uploader";

const { alerts, showAlert } = useInlineAlert()
const maxFiles = inject('maxFiles')
const maxRefFiles = inject('maxRefFiles')
const supportedFormats = inject('supportedFormats')

const fileInput = ref(null)
const refFileInput = ref(null)
const isError = ref(false)
const errorMessage = ref('')

watch(fileWarning, (newV)=>{
  if (newV.length > 0) {
    showAlert({message:newV})
    fileWarning.value = ''
  }
})

function validateFiles(final = false) {
  let result = files.value.length !==0
  files.value.forEach((el) => {
    if ( el.instructions.trim().length === 0 ) { result = false; if (final) el.instructionsError = true}
  })
  return result
}

function onInputChange(e) {
  addFiles(e.target.files)
  e.target.value = null // allows to select the same file again
}
function onRefInputChange(e) {
  addRefFiles(e.target.files)
  e.target.value = null // allows to select the same file again
}

function clickAddMore() {
  fileInput.value.click();
}
function clickAddRef() {
  refFileInput.value.click();
}

function clickNext() {
  if (validateFiles(true)) {
    isLoading.value = true;
    uploadFiles(files.value.concat(refFiles.value))
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            console.log("Success Uploading file")
            isLoading.value = false;
            emit('step1to2')
          } else {
            console.log("Error Loading Free Quote: " + data.error)
            errorMessage.value = data.error;
            isError.value = true;
          }
        })
        .catch((error)=>console.log(error))
        .finally(()=>{
          isLoading.value = false;
        })
  }
}

const { uploadFiles } = createUploader(inject('apiURL'), uuid )

</script>



<template>
  <!-- ******* -->
  <!--  Step 1. Collecting files from user.-->
  <!-- ******* -->
  <DropZone class="drop-area" @files-dropped="addFiles" #default="{ dropZoneActive }">
    <!-- initial big orange button-->
    <div class="fq-animation-slide-bottom-small" v-show="!files.length && !refFiles.length">
      <label for="file-input" class="fq-button fq-download-button fq-button-primary fq-width-small fq-button-circle-effect">
        <div class="fq-grid fq-grid-collapse fq-flex-center fq-flex-middle">
          <div class="fq-icon-download fq-icon">
            <svg width="23.1" height="20" viewBox="0 0 1792 1551.5" xmlns="http://www.w3.org/2000/svg">
              <path fill="#fff" d="M1344,1344c0-17.3-6.3-32.3-19-45s-27.7-19-45-19-32.3,6.3-45,19-19,27.7-19,45 6.3,32.3,19,45 27.7,19,45,19 32.3-6.3,45-19S1344,1361.3,1344,1344zm256,0c0-17.3-6.3-32.3-19-45s-27.7-19-45-19-32.3,6.3-45,19-19,27.7-19,45 6.3,32.3,19,45 27.7,19,45,19 32.3-6.3,45-19S1600,1361.3,1600,1344zM1728,1120v320c0,26.7-9.3,49.3-28,68s-41.3,28-68,28H160c-26.7,0-49.3-9.3-68-28s-28-41.3-28-68v-320c0-26.7,9.3-49.3,28-68s41.3-28,68-28h465l135,136c38.7,37.3,84,56,136,56s97.3-18.7,136-56l136-136h464c26.7,0,49.3,9.3,68,28S1728,1093.3,1728,1120zM1403,551c11.3,27.3,6.7,50.7-14,70l-448,448c-12,12.7-27,19-45,19s-33-6.3-45-19L403,621c-20.7-19.3-25.3-42.7-14-70 11.3-26,31-39,59-39h256V64c0-17.3,6.3-32.3,19-45s27.7-19,45-19h256c17.3,0,32.3,6.3,45,19s19,27.7,19,45v448h256C1372,512,1391.7,525,1403,551z"/>
            </svg>
          </div>
          <div class="fq-text-uppercase fq-text-bold">Upload Image</div>
        </div>
        <input
            type="file" id="file-input" ref="fileInput"
            :accept="'image/*, .' + supportedFormats.join(', .')"
            @change="onInputChange" />
        <input
            type="file" id="ref-file-input" ref="refFileInput"
            :accept="'image/*, .' + supportedFormats.join(', .')"
            @change="onRefInputChange" />
      </label>
      <div class="fq-text-center fq-text-small fq-margin-top">
        <span v-if="dropZoneActive">Drop image here</span>
        <span v-else>Drag image here or click to upload</span>
      </div>
    </div>

    <!--tit-->
    <div v-show="files.length || refFiles.length" class="fq-top-tit fq-text-center fq-animation-fade">
      Your Photo<span v-show="files.length>1">s</span>
    </div>

    <!--imgs list-->
    <FilePreview
        class="fq-animation-fade"
        v-for="file of files" :key="file.id" :file="file"
        tag="div"
        @remove="removeFile"/>
    <InlineAlert v-for="alert of alerts" :key="alert.id" :alert="alert" @close="( id )=>{ alerts.splice( alerts.findIndex( (el)=>el.id === id ),1 )}"/>

    <!--btns (download)-->
    <div v-show="(files.length || refFiles.length) && ((files.length < maxFiles) || (refFiles.length < maxRefFiles))" class="fq-margin-large-top fq-animation-fade fq-text-center">
      <div class="fq-grid fq-grid-collapse fq-grid-divider fq-flex-middle fq-flex-center fq-load-imgs-btns fq-btns">
        <div class="fq-first-column">
          <button class="fq-button fq-load-imgs-btn" @click.prevent="clickAddMore" :disabled="files.length >= maxFiles" >
            + Add Photo
          </button>
        </div>
        <div class="">
          <button href="" class="fq-button fq-load-imgs-btn" @click.prevent="clickAddRef" :disabled="refFiles.length >= maxRefFiles" >
            + Reference Photo
          </button>
        </div>
      </div>
    </div>

    <!--tit-->
    <div v-show="refFiles.length" class="fq-top-tit fq-text-center fq-margin-top fq-animation-fade">
      Reference Photo
    </div>
    <!--reference img-->
    <FilePreview
        class="fq-animation-fade"
        v-for="file of refFiles" :key="file.id" :file="file"
        tag="div"
        @remove="removeRefFile"
        selfName="refFile"
        enableNotesEditor="false"
        placeholder="Leave your comments / instructions"/>

    <!--btns (Next) -->
    <div v-show="files.length || refFiles.length"  class="fq-margin-large-top fq-text-center fq-animation-fade fq-btns">
      <button class="fq-button fq-button-orange fq-button-big fq-button-circle-effect fq-button-next" @click.prevent="clickNext">
        Next &rarr;
      </button>
    </div>
    <ErrorScreen v-if="isError" :error-message="errorMessage"/>
    <LoadingScreen v-if="isLoading"/>
  </DropZone>
</template>


<style scoped>
  .fq-load-imgs-btns {
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: .2s ease-in-out;
    overflow: hidden;
  }
  .fq-load-imgs-btns:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }
  .fq-load-imgs-btns .fq-button {
    color: inherit;
    background-color: #fff;
    border-radius: 0;
    padding: 18px 18px;
  }
  .fq-load-imgs-btns .fq-button:hover {
    color: #fff;
    background-color: #ffb231;
  }
  .fq-load-imgs-btns .fq-button[disabled],
  .fq-load-imgs-btns .fq-button[disabled]:hover {
    cursor: not-allowed;
    color: #cecece;
    background-color: #fff;
  }
</style>