<script setup>
import {
  defineModel,
  inject,
  ref,
} from "vue";

const apiUrl = inject('apiURL')
const { uuid } = inject('uuid')
const secondsInMinute = 59;

const minutesLeft = defineModel('minutesLeft')
const isLoading = defineModel('isLoading')

const initialMinutes = minutesLeft.value
minutesLeft.value--
let secondsLeft = ref(secondsInMinute);
let countdownInterval = null

function refreshCountdown() {
  if (secondsLeft.value>0) {
    secondsLeft.value--
  } else {
    if (minutesLeft.value-1 < 0) {
      secondsLeft.value = 0
      clearInterval(countdownInterval)
      refreshState()
    } else {
      secondsLeft.value = secondsInMinute
      minutesLeft.value--
    }
  }
}
if (minutesLeft.value !==false) {
  countdownInterval = setInterval(refreshCountdown, 1000);
}

function refreshState(){
    isLoading.value = true
    let formData = new FormData()
    Object.entries({
      action: 'get',
      uuid: uuid.value,
    }).forEach(([key, value]) => formData.append(key, value))

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            let mL = isNaN(parseInt(data.maintenance)) ? false : parseInt(data.maintenance)
            secondsLeft.value = 0
            minutesLeft.value = mL
            if (mL !== false) {
              countdownInterval = setInterval(refreshCountdown, 1000);
            } else {
              isLoading.value = false // finally method below doesn't work because reactive 'maintenance' property kills this component first
              window.location.reload();
            }
          } else {
            console.log("Error checking maintenance end: " + data.error)
          }
        })
        .catch((error)=>console.log(error))
        .finally(()=>{
          isLoading.value = false
        })
}

import LoadingScreen from "@/components/partials/LoadingScreen.vue";

</script>


<template>

  <!-- ******* -->
  <!-- Maintenance screen -->
  <!-- ******* -->
  <div class="fq-container fq-box-shadow-medium fq-flex fq-flex-center fq-flex-middle fq-flex-column fq-overflow-auto fq-position-relative fq-animation-fade fq-box-shadow-hover-large">
    <div>
      <!--mess-->
      <div class="fq-text-center fq-margin-large-bottom">
        <!--Success-->
        <div class="fq-grid fq-grid-collapse fq-flex-center fq-flex-column">
          <div class="fq-text-primary fq-countdown">
            {{minutesLeft.toString().padStart(2,'0') + ":" + secondsLeft.toString().padStart(2,'0')}}
          </div>
          <div class="fq-margin-medium-top">Free Quote Form is in maintenance mode for {{initialMinutes}} minute{{initialMinutes>1?'s':''}}</div>
          <div class="fq-margin-medium-top" style="line-height:1.6;"> It will be available as quick as countdown above is off.</div>
        </div>
      </div>
    </div>
  </div>

  <LoadingScreen v-if="isLoading"/>

</template>
<style scoped>
.fq-countdown {
  font-size:40pt;
}
</style>