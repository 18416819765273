import { inject, ref } from 'vue'

export default function () {
    const apiUrl = inject('apiURL')
    const uuidExpirationMinutes = inject('uuidExpirationMinutes')
    const freeQuote = ref({} )
    const supportedFormats = ref([] )
    const translations = ref([] )
    const isLoading = ref(false);
    const isMaintenance = ref(false);
    const uuid = ref('')
    const updateUUID = function(newVal){ uuid.value = newVal }
    function initFreeQuote() {
        initUUID()
        const url = `${apiUrl}?action=get&uuid=${uuid.value}`
        isLoading.value = true
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    freeQuote.value = data.data
                    supportedFormats.value = data.formats ?? ['jpg']
                    translations.value = data.translations ?? []
                    isLoading.value = false
                    isMaintenance.value = isNaN(parseInt(data.maintenance)) ? false : parseInt(data.maintenance)
                    console.log("Success Loading Free Quote: ")
                } else {
                    console.log("Error Loading Free Quote: " + data.error)
                }
            })
            .catch((error)=>console.log(error))
    }

    function initUUID(){
        if ( isLocalStorageAvailable() ) {
            let uuidDate = new Date(localStorage.getItem('uuidDate'));
            let currentDate = new Date();
            let dateInterval = (currentDate - uuidDate) / 60000;  // in minutes
            console.log(dateInterval);
            if (dateInterval > uuidExpirationMinutes || !localStorage.getItem('uuid')) {
                updateUUID( crypto.randomUUID() )
            } else {
                updateUUID( localStorage.getItem('uuid') )
            }
            localStorage.setItem('uuid', uuid.value)
            localStorage.setItem('uuidDate', currentDate.toString())
        } else {
            updateUUID( uuid.value ?? crypto.randomUUID() )
        }
    }

    // type is a String, can be 'localStorage' or 'sessionStorage'
    function isLocalStorageAvailable(type='localStorage') {
        let storage;
        try {
            storage = window[type];
            const x = "__storage_test__";
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                e.name === "QuotaExceededError" &&
                storage &&
                storage.length !== 0
            );
        }
    }

    return {
        freeQuote,
        isLoading,
        initFreeQuote,
        isLocalStorageAvailable,
        initUUID,
        updateUUID,
        uuid,
        supportedFormats,
        translations,
        isMaintenance
    }
}
